import api from "@/utils/api";
import VueRouter from '../../../route/router'
import router from '../../../route/router'
import config from "@/config";

const url = {
  GET: "/hm/menu/userMenu"
}

const state = {
  resource: null, // 路由信息
  menuList: null,
  routerHome: null, // 首页菜单信息
  system: null, // 系统管理菜单信息
  cliUser: null, //个人中心菜单信息
  tags: [], // tags显示菜单路由
}

const getters = {
  resource: state => {
    return state.resource //路由信息
  },
  menuList: state => {
    return state.menuList     // 菜单信息
  },
  routerHome : state => {
    return state.routerHome     // 首页菜单信息
  },
  system : state => {
    return state.system     // 首页菜单信息
  },
  cliUser : state => {
    return state.cliUser     // 首页菜单信息
  },
  tags: state => {
    return state.tags // tags显示路由
  },

}

const mutations = {
  saveMenuList(state, menuList) {
    state.menuList = menuList
  },
  saveResource(state, resource) {
    state.resource = resource
  },
  saveRouterHome(state, routerHome) {
    state.routerHome = routerHome
  },
  saveSystem(state, system) {
    state.system = system
  },
  saveCliUser(state, cliUser) {
    state.cliUser = cliUser
  },
  // 设置tags标签数组
  saveTags(state, tags) {
    window.sessionStorage.setItem('menuTags', JSON.stringify(tags));
    state.tags = tags
  },
}

const actions = {
  // 菜单路由资源
  async getResource({dispatch, state}) {
    if (!state.resource) {
      await dispatch("getRouters");
    }
    return state.menuList;
  },
  async getRouters({commit}) {
    let res = await api.post(url.GET);
    if (res && res.code === 200) {
      const filterList = ['index','cliUser','system']
      const resource = res.data.filter(item => !filterList.includes(item.path) )
      const home = res.data.filter( item => item.path === 'index' )
      const system = res.data.filter( item => item.path === 'system' )
      const cliUser = res.data.filter( item => item.path === 'cliUser' )
      commit("saveResource", resource);
      commit("saveRouterHome", home);
      commit("saveSystem", system);
      commit("saveCliUser", cliUser);
      commit("saveMenuList", res.data);
    }
  },
  // 关闭当前路由
  closeTag({state, commit}, {routerName, go = -1}) {
    if (state.tags.length > 1) {
      commit('saveTags', state.tags.filter((item) => item.name !== routerName))
      if (state.tags.length === 0) {
        VueRouter.push({path: "/classicIndex"})
      } else  {
        VueRouter.go(go)
      }
    } else {
      commit('saveTags',[])
      VueRouter.push({path: "/classicIndex"});
    }
  },


  //排除黑名单tags
  excludeTage({commit}, routerName) {
    let tags = JSON.parse(window.sessionStorage.getItem('menuTags'))
    if (tags !== null && tags.length > 0) {
      // 获取config 黑名单
      let blackList = config.router_black_list
      let index = blackList.findIndex((router) => router === routerName) // 路由名
      if(index >= 0) {
        router.push('/classicIndex')
      }
      // 排除黑名单路由
      let newTage = tags.filter((item) => {
        let index = blackList?.findIndex((router) => item.name === router)
        return index < 0;
      })
      commit("saveTags", newTage);
    } else {
      commit("saveTags", []);
      router.push({path: "/classicIndex"})
    }
  }

}

export default {
  state,
  getters,
  mutations,
  actions
}
