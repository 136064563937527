import Vue from 'vue'
import App from './App.vue'
// import 'default-passive-events'
import init from './utils/init'
import api from './utils/api'
import components from "./components/index"
import store from './store/index'
import router from './route/router'
import '@/components/Element/element'
import VueContextMenu from 'vue-contextmenu'
import Print from 'vue-print-nb'
import _ from 'lodash'
import "uno.css";
import VueSocketIO from "vue-socket.io";
Vue.use(VueContextMenu)
Vue.use(Print);
let uri = init.connection;
if(localStorage.getItem("token")){uri += "/?token=" + localStorage.getItem("token");}
Vue.use(new VueSocketIO({debug: init.debug, connection: uri}))
// 自动注册组件
Object.keys( components ).forEach((item) => {Vue.component(item, components[item])})
Vue.config.productionTip = false
Vue.prototype.$config = init;
Vue.prototype.$curl = api;
// Vue.prototype.$utils = utils;
Vue.prototype._ = _
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
